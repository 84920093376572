<template>
  <v-container class="full-height">
    <div class="d-flex justify-center align-center full-height">
      <v-card min-width="50%">
        <v-card-title>
          <span class="mainTitle" style="font-size: 20px"> Forgot Password </span>
        </v-card-title>
        <ValidationObserver ref="form" v-slot="{ valid }">
          <v-card-text>
            <ValidationProvider v-slot="{ errors }" name="Email" rules="required|email">
              <label-with-text-field item-field-name="Email Address" v-model="userEmail" />
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="back">Back</v-btn>
            <v-btn @click="submit" color="primary" :disabled="!valid">Submit</v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import api from '@/api';
import { forgetPassword } from '@/api/auth/sso';
import LabelWithTextField from '../../components/customFormLabel/labelWithTextField';

export default {
  components: { LabelWithTextField },
  data() {
    return {
      userEmail: null,
    };
  },
  methods: {
    async submit() {
      try {
        await forgetPassword(api, { email: this.userEmail });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: `An email has been sent. Please check your email`,
          type: 'success',
          color: 'success',
        });
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
